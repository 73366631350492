<template lang="">
    <div>
        <b-card>            
            <div class="row pr-1">
                <input-text id="input-produto" class="col-12 col-md-6" :label="$t('PRODUTOS.TABELA.PRODUTO')" v-model="form.nome" ref="nome" required />
                <input-currency id="input-valor" class="col-12 col-md-2" :label="$t('PRODUTOS.TABELA.VALOR')" v-model="form.valor" ref="valor" required />
                <input-select id="input-estabelecimento" class="col-12 col-md-4" :label="$t('PRODUTOS.TABELA.ESTABELECIMENTO')" :options="opcoes.estabelecimentos" v-model="form.estabelecimentoId" ref="estabelecimento" required>
                    <template #label>
                        <div class="d-inline-flex align-items-center">
                            <span class="mr-2">
                                {{$t('PRODUTOS.TABELA.ESTABELECIMENTO')}}
                            </span>
                            <feather type="help-circle" size="14" v-b-tooltip.hover :title="$t('PRODUTOS.TOOLTIP')"></feather>
                        </div>
                    </template>
                </input-select>
                
                <div class="col-12 mt-4 d-flex justify-content-end">
                    <b-button id="btn-cancelar" @click="cancelar" variant="outline-success" >
                        Cancelar
                    </b-button>        
                    <b-button id="btn-salvar" @click="salvar" v-if="form.id ? permissao.editar : permissao.criar" class="ml-2" variant="success">
                        Salvar
                    </b-button>        
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import { InputText, InputCurrency, InputSelect } from "@/components/inputs";
import ProdutosService from "@/common/services/produtos/produtos.service";
import MinhaContaService from "@/common/services/usuario/minha-conta.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import helpers from "@/common/utils/helpers";
export default {
  props: ["id"],
  components: {
    InputText,
    InputCurrency,
    InputSelect,
  },
  data: function () {
    return {
      form: {
        nome: "",
        valor: 0,
        estabelecimentoId: "",
      },
      opcoes: {
        situacoes: helpers.Situacao,
        estabelecimentos: [],
      },
    };
  },
  methods: {
    salvar: function () {
      this.confirmar("", this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")).then(
        (confirmado) => {
          if (!confirmado) return;

          if (!this.validarFormulario()) return false;

          this.$store.dispatch(START_LOADING);
          ProdutosService.salvar(this.form)
            .then(() => {
              this.$router.push({ name: "produtos" });
            })
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      );
    },
    cancelar: function () {
      this.$router.push({ name: "produtos" });
    },
    buscar: function () {
      this.$store.dispatch(START_LOADING);
      ProdutosService.buscar(this.id)
        .then((res) => {
          this.form = res.data.data;
          this.form.valor = helpers.formatarValor(this.form.valor);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarEstabelecimentos: function () {
      this.$store.dispatch(START_LOADING);
      MinhaContaService.buscarEstabelecimento()
        .then((res) => {
          this.opcoes.estabelecimentos = res.data.data.estabelecimentos.map(
            (el) => {
              return {
                value: el.id,
                text: el.nome,
              };
            }
          );
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("PRODUTOS.TITULO_LISTA"), to: "/produtos" },
      {
        titulo: this.$t(
          `PRODUTOS.TITULO_FORMULARIO_${!this.id ? "NOVO" : "EDICAO"}`
        ),
      },
    ]);
    this.buscarEstabelecimentos();
    if (this.id) {
      this.buscar();
    } else {
      this.form.estabelecimentoId =
        this.$store.getters["estabelecimentoSelecionado"];
    }
  },
  computed: {
    permissao: function () {
      return helpers.validarAcesso("Produto");
    },
  },
};
</script>
<style lang="">
</style>